"use client";

import { useEffect, type FC } from "react";
import { hexToHSL } from "~/lib/utils";
export interface OrganizationThemeLoaderProps {
  theme: {
    primaryColor: string;
  };
}
export const OrganizationThemeLoader: FC<OrganizationThemeLoaderProps> = ({
  theme
}) => {
  // Note: Due to bundling and caching optimization there is not really a good way
  // to generate CSS on the server and dynamically return it to the client.
  //
  // Instead this component, runs once on the client and globally overrides the
  // respective CSS variables (see ~/styles/globals.css) with the provided color
  // from the theme.

  useEffect(() => {
    const hsl = hexToHSL(theme.primaryColor);
    if (!hsl) {
      return;
    }
    const hslValueList = hsl.array();
    if (!hslValueList || hslValueList.length < 3) {
      return;
    }
    const hslString = `${hslValueList[0]} ${hslValueList[1]}% ${hslValueList[2]}%`;
    const cssText = [`--primary: ${hslString}`, `--ring: ${hslString}`].join(";");
    const root = document.documentElement;
    root.style.cssText = cssText;
  }, [theme]);
  return <></>;
};