"use client";

import { type FC, useState, useCallback } from "react";
import { toast } from "sonner";
import { Button } from "~/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "~/components/ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "~/components/ui/select";
import { api } from "~/trpc/react";
import { type UserInOrganization } from "~/components/domain/organization/model/organization-model";
export interface OrganizationMemberEditDialogProps {
  userInOrganization: UserInOrganization;
  onClose: (reload: boolean) => void;
}
export const OrganizationMemberEditDialog: FC<OrganizationMemberEditDialogProps> = ({
  userInOrganization,
  onClose
}) => {
  const [dialogisOpen, setDialogisOpen] = useState(true);
  const [currentRole, setCurrentRole] = useState<Role>(userInOrganization.role as Role);
  const updateRoleMutation = api.organization.updateUserinOrganization.useMutation();
  type Role = "ADMIN" | "USER";
  const roles: Role[] = ["ADMIN", "USER"];
  const handleRoleChange = (role: Role) => {
    setCurrentRole(role);
  };
  const handleSubmit = useCallback(async () => {
    const result = await updateRoleMutation.mutateAsync({
      userId: userInOrganization.user.id,
      userRole: currentRole
    });
    if (!result) {
      toast.error("Nutzer Rechte konnten nicht aktualisiert werden. Bitte laden Sie die Seite neu und versuchen Sie es erneut.");
      return;
    }
    onClose(true);
  }, [currentRole, onClose, updateRoleMutation, userInOrganization.user]);
  return <>
      <Dialog open={dialogisOpen} onOpenChange={isOpen => {
      setDialogisOpen(isOpen);
      if (!isOpen) onClose(false);
    }} data-sentry-element="Dialog" data-sentry-source-file="organization-member-edit-dialog.tsx">
        <DialogContent className="sm:max-w-md" data-sentry-element="DialogContent" data-sentry-source-file="organization-member-edit-dialog.tsx">
          <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="organization-member-edit-dialog.tsx">
            <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="organization-member-edit-dialog.tsx">
              <span className="text-base font-bold">
                {[userInOrganization.user.firstname, " ", userInOrganization.user.lastname]}
              </span>
            </DialogTitle>
            <DialogDescription asChild data-sentry-element="DialogDescription" data-sentry-source-file="organization-member-edit-dialog.tsx">
              <div className="h-4 w-full border-b border-solid" />
            </DialogDescription>
          </DialogHeader>
          <div className="relative flex max-w-full flex-col gap-1">
            <div className="pt-2">Nutzer Rolle:</div>
            <Select onValueChange={handleRoleChange} defaultValue={currentRole} data-sentry-element="Select" data-sentry-source-file="organization-member-edit-dialog.tsx">
              <SelectTrigger className="w-full text-base" data-sentry-element="SelectTrigger" data-sentry-source-file="organization-member-edit-dialog.tsx">
                <SelectValue placeholder={currentRole} data-sentry-element="SelectValue" data-sentry-source-file="organization-member-edit-dialog.tsx" />
              </SelectTrigger>
              <SelectContent className="grid gap-2 rounded-sm" data-sentry-element="SelectContent" data-sentry-source-file="organization-member-edit-dialog.tsx">
                {roles.map(role => <SelectItem key={role} value={role}>
                    {role}
                  </SelectItem>)}
              </SelectContent>
            </Select>
          </div>
          <DialogFooter className="flex flex-row flex-wrap" data-sentry-element="DialogFooter" data-sentry-source-file="organization-member-edit-dialog.tsx">
            <div className="controls mt-4 flex w-full flex-row justify-center self-center"></div>
            <div className="h-4 w-full border-b border-solid" />
            <div className="flex w-full flex-row justify-end gap-2 pt-4">
              <div className="flex grow-0 flex-row items-center justify-start gap-1">
                <Button type="button" size="sm" variant="ghost" className="w-24 rounded-full hover:border-[0.5px]" onClick={() => {
                onClose(false);
              }} data-sentry-element="Button" data-sentry-source-file="organization-member-edit-dialog.tsx">
                  Verwerfen
                </Button>
              </div>
              <div className="flex grow-0 flex-row items-center justify-end gap-1">
                <Button type="button" size="sm" variant="ghost" className="w-28 rounded-full border-[0.5px] bg-[#FCFAF8]" onClick={handleSubmit} // Trigger handleSubmit on click
              data-sentry-element="Button" data-sentry-source-file="organization-member-edit-dialog.tsx">
                  Speichern
                </Button>
              </div>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>;
};