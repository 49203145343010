"use client";

import { type FC } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { toast } from "sonner";
import { cn } from "~/lib/utils";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "~/components/ui/form";
import { LoadingDots } from "~/components/ui/loading-dots";
import { api } from "~/trpc/react";
import { type Organization } from "../model/organization-model";
const formSchema = z.object({
  name: z.string().min(3, {
    message: "Der Name Ihrer Organization muss mindestens drei Zeichen lang sein."
  })
});
export interface OrganizationFormProps {
  organization: Organization;
  className?: string;
}
export const OrganizationForm: FC<OrganizationFormProps> = ({
  className,
  organization
}) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: organization.name
    }
  });
  const updateOrganizationProfile = api.organization.updateOrganizationProfile.useMutation({
    onSuccess: () => {
      void onUpdateCompleted();
    },
    onError: () => {
      void onUpdateError();
    }
  });

  // 2. Define a submit handler.
  function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.

    updateOrganizationProfile.mutate({
      name: values.name
    });
  }
  async function onUpdateCompleted() {
    toast.success("Das Profil deiner Organisation wurde erfolgreich aktualisert.");
  }
  async function onUpdateError() {
    toast.error("Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.");
  }
  return <Form {...form} data-sentry-element="Form" data-sentry-component="OrganizationForm" data-sentry-source-file="organization-form.tsx">
      <form onSubmit={form.handleSubmit(onSubmit)} className={cn("max-w-md space-y-6", className)}>
        <FormField control={form.control} name="name" render={({
        field
      }) => <FormItem>
              <FormLabel>Name</FormLabel>

              <FormControl>
                <Input placeholder="München" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>} data-sentry-element="FormField" data-sentry-source-file="organization-form.tsx" />
        <div className="py-4">
          <Button type="submit" disabled={updateOrganizationProfile.isPending} size="sm" variant="ghost" className={`${updateOrganizationProfile.isPending ? "cursor-not-allowed bg-muted" : ""} flex h-10 w-24 items-center justify-center rounded-full border-[0.5px] bg-[#FCFAF8] transition-all`} data-sentry-element="Button" data-sentry-source-file="organization-form.tsx">
            {updateOrganizationProfile.isPending ? <LoadingDots /> : <span>Speichern</span>}
          </Button>
        </div>
      </form>
    </Form>;
};