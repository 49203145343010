"use client";

import { type FC, useState, useMemo } from "react";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "~/components/ui/table";
import { api } from "~/trpc/react";
import { type UserInOrganization } from "~/components/domain/organization/model/organization-model";
import { OrganizationMemberEditDialog } from "./organization-member-edit-dialog";
export const OrganizationMemberTable: FC = () => {
  const query = api.organization.getUsersInOrganization.useQuery();
  const [selectedMember, setSelectedMember] = useState<UserInOrganization | null>(null);
  const [filterValue, setFilterValue] = useState<string>("");
  const filteredOrganizationMemberList = useMemo(() => {
    if (!query.data) {
      return [];
    }
    if (filterValue === "") {
      return query.data;
    }
    const queryLower = filterValue.toLocaleLowerCase();
    return query.data.filter((om: UserInOrganization) => {
      const user = om.user;
      return om.role.toLocaleLowerCase().includes(queryLower) || user.email.toLocaleLowerCase().includes(queryLower) || user.firstname.toLocaleLowerCase().includes(queryLower) || user.lastname.toLocaleLowerCase().includes(queryLower);
    });
  }, [filterValue, query.data]);
  if (!query.data) {
    return <></>;
  }
  return <>
      <Input placeholder="Benutzer filtern..." value={filterValue} onChange={event => {
      return setFilterValue(event.currentTarget.value);
    }} className="h-8 w-[200px] lg:w-[350px]" data-sentry-element="Input" data-sentry-source-file="organization-member-table.tsx" />
      <Table data-sentry-element="Table" data-sentry-source-file="organization-member-table.tsx">
        <TableHeader data-sentry-element="TableHeader" data-sentry-source-file="organization-member-table.tsx">
          <TableRow data-sentry-element="TableRow" data-sentry-source-file="organization-member-table.tsx">
            <TableHead data-sentry-element="TableHead" data-sentry-source-file="organization-member-table.tsx">Name</TableHead>
            <TableHead data-sentry-element="TableHead" data-sentry-source-file="organization-member-table.tsx">Email</TableHead>
            <TableHead data-sentry-element="TableHead" data-sentry-source-file="organization-member-table.tsx">Rolle</TableHead>
            <TableHead data-sentry-element="TableHead" data-sentry-source-file="organization-member-table.tsx">Aktion</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody data-sentry-element="TableBody" data-sentry-source-file="organization-member-table.tsx">
          {filteredOrganizationMemberList.map(member => <TableRow key={member.id}>
              <TableCell className="font-medium">
                {member.user.firstname} {member.user.lastname}
              </TableCell>
              <TableCell>{member.user.email}</TableCell>
              <TableCell>{member.role}</TableCell>
              <TableCell>
                <Button variant={"link"} className="px-0" onClick={() => setSelectedMember(member)}>
                  Bearbeiten
                </Button>
              </TableCell>
            </TableRow>)}
        </TableBody>
      </Table>

      {selectedMember && <OrganizationMemberEditDialog userInOrganization={selectedMember} onClose={reload => {
      setSelectedMember(null);
      if (reload) {
        void query.refetch();
      }
    }} />}
    </>;
};