"use client";

import { type FC, useState } from "react";
import Image from "next/image";
import { HiArrowUpTray } from "react-icons/hi2";
import { toast } from "sonner";
import { cn } from "~/lib/utils";
import { api } from "~/trpc/react";
import { LoadingDots } from "~/components/ui/loading-dots";
import { InputImage } from "~/components/ui/input-image";
import { useUploadToObjectStorageAndStoreFile } from "~/lib/hooks/useUploadToObjectStorageAndStoreFile";
export interface OrganizationLogoUploadProps {
  organization: {
    id: string;
    logoUrl: string | null | undefined;
  };
  className?: string;
}
export const OrganizationLogoUpload: FC<OrganizationLogoUploadProps> = ({
  organization,
  className
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [src, setSrc] = useState<string | null | undefined>(organization.logoUrl);
  const storeAndUploadFile = useUploadToObjectStorageAndStoreFile();
  const updateOrganizationProfile = api.organization.updateOrganizationProfile.useMutation();
  const handleImageInputChange = async (file: File) => {
    setLoading(true);
    const storeAndUploadFileResult = await storeAndUploadFile.mutateAsync({
      file,
      metadata: {
        ownerId: organization.id,
        ownerType: "organization"
      }
    });
    if (storeAndUploadFileResult.error) {
      toast.error(storeAndUploadFileResult.error.message);
      setLoading(false);
      return;
    }
    const updatedOrganization = await updateOrganizationProfile.mutateAsync({
      logoFileInput: storeAndUploadFileResult.result
    });
    if (!updatedOrganization) {
      toast.error("Beim Speichern Ihres Profils in der Datenbank ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu und versuchen Sie es erneut.");
      setLoading(false);
      return;
    }
    setSrc(updatedOrganization.logoUrl);
    setLoading(false);
    window.location.reload();
  };
  return <>
      <InputImage className={cn(["relative h-32 w-32 rounded-lg", className])} onChange={handleImageInputChange} data-sentry-element="InputImage" data-sentry-source-file="organization-logo-upload.tsx">
        <div className="flex h-32 w-32 shrink-0 flex-row items-center justify-center overflow-hidden rounded-lg">
          {!src && <div className="select-none">
              <HiArrowUpTray className="h-6 w-6" />
            </div>}
          {src && <Image width={200} height={200} src={src} alt="Profilbild des Benutzers" className="h-full w-full select-none" />}
          {loading && <div className="absolute inset-0 flex flex-row items-center justify-center bg-black/30">
              <LoadingDots color="#fff" />
            </div>}
        </div>
      </InputImage>
    </>;
};