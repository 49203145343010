"use client";

import React, { type FC, type PropsWithChildren, createContext, useContext, useState } from "react";

// Assuming you have an Organization type defined in your models
import { type Organization } from "../../model/organization-model";

// Define context
interface OrganizationContextType {
  organization: Organization;
  setOrganization: (organization: Organization) => void;
}

// Create the context with default values
const OrganizationContext = createContext<OrganizationContextType | undefined>(undefined);
export interface OrganizationProviderProps extends PropsWithChildren {
  organization: Organization;
}

// Provider component
export const OrganizationProvider: FC<OrganizationProviderProps> = ({
  children,
  ...props
}) => {
  const [organization, setOrganization] = useState<Organization>(props.organization);
  return <OrganizationContext.Provider value={{
    organization,
    setOrganization
  }} data-sentry-element="unknown" data-sentry-component="OrganizationProvider" data-sentry-source-file="organization-provider.tsx">
      {children}
    </OrganizationContext.Provider>;
};

// Custom hook to use OrganizationContext
export const useOrganization = (): OrganizationContextType => {
  const context = useContext(OrganizationContext);
  if (context === undefined) {
    throw new Error("useOrganization must be used within an OrganizationProvider");
  }
  return context;
};