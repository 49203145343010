"use client";

import { type FC } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { toast } from "sonner";
import { cn } from "~/lib/utils";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "~/components/ui/form";
import { LoadingDots } from "~/components/ui/loading-dots";
import { api } from "~/trpc/react";
import { type Organization } from "../model/organization-model";
import { HiInformationCircle } from "react-icons/hi2";
const formSchema = z.object({
  emailForwardingAddress: z.string().email({
    message: "Die Weiterleitungs-Adresse muss eine gültige E-Mail-Adresse sein. Bitte überprüfen Sie Ihre Eingabe."
  }).or(z.literal("")) // allow empty string to set to null
});
export interface OrganizationContactFormProps {
  organization: Organization;
  className?: string;
}
export const OrganizationContactForm: FC<OrganizationContactFormProps> = ({
  className,
  organization
}) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      emailForwardingAddress: organization?.metadata?.emailForwardingAddress ?? ""
    }
  });
  const updateOrganizationMetadataMutation = api.organization.updateOrganizationMetadata.useMutation({
    onSuccess: () => {
      void onUpdateCompleted();
    },
    onError: () => {
      void onUpdateError();
    }
  });

  // 2. Define a submit handler.
  function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.

    updateOrganizationMetadataMutation.mutate({
      emailForwardingAddress: values.emailForwardingAddress === "" // make sure we don't send an empty string
      ? null : values.emailForwardingAddress
    });
  }
  async function onUpdateCompleted() {
    toast.success("Das Profil deiner Organisation wurde erfolgreich aktualisert.");
  }
  async function onUpdateError() {
    toast.error("Leider ist ein Fehler aufgetreten. Versuchen Sie die Seite neu zu laden und es erneut zu probieren.");
  }
  return <div className="flex flex-col gap-4 pt-2" data-sentry-component="OrganizationContactForm" data-sentry-source-file="organization-contact-form.tsx">
      <div className="text-lg">Weiterleitungs-Adresse</div>
      <div className="mb-2 flex flex-row items-start justify-start gap-2 text-xs text-muted-foreground">
        <HiInformationCircle className="h-4 w-4 shrink-0" data-sentry-element="HiInformationCircle" data-sentry-source-file="organization-contact-form.tsx" />
        Konfigurieren Sie eine E-Mail Adresse, an die E-Mails weitergeleitet
        werden sollen. Dabei kann es sich z.B. um Benachrichtigungen der
        Plattform oder Feedback von Benutzer handeln.
      </div>
      <Form {...form} data-sentry-element="Form" data-sentry-source-file="organization-contact-form.tsx">
        <form onSubmit={form.handleSubmit(onSubmit)} className={cn("max-w-md space-y-6", className)}>
          <FormField control={form.control} name="emailForwardingAddress" render={({
          field
        }) => <FormItem>
                <FormLabel>Weiterleitungs-Adresse</FormLabel>

                <FormControl>
                  <Input placeholder="klimaschutz@stadt.de" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>} data-sentry-element="FormField" data-sentry-source-file="organization-contact-form.tsx" />
          <div className="py-4">
            <Button type="submit" disabled={updateOrganizationMetadataMutation.isPending} size="sm" variant="ghost" className={`${updateOrganizationMetadataMutation.isPending ? "cursor-not-allowed bg-muted" : ""} flex h-10 w-24 items-center justify-center rounded-full border-[0.5px] bg-[#FCFAF8] transition-all`} data-sentry-element="Button" data-sentry-source-file="organization-contact-form.tsx">
              {updateOrganizationMetadataMutation.isPending ? <LoadingDots /> : <span>Speichern</span>}
            </Button>
          </div>
        </form>
      </Form>
    </div>;
};